import React, { Children } from 'react';
import type { ComponentType, ReactNode } from 'react';

import { fg } from '@confluence/feature-gating';

import { useShowNotTellFGAAExperiment, useShowNotTellFGABExperiment } from '../hooks';

import { COHORTS } from './experiment-config';

type ShowNotTellExperimentProps = {
	touchpointId: string;
	Component: ComponentType<any>;
};

export const ShowNotTellExperimentCohortContext = React.createContext(
	COHORTS.NOT_ENROLLED.toString(),
);

export function withShowNotTellExperiment<P>({
	touchpointId,
	Component,
}: ShowNotTellExperimentProps): React.FC<P & { children?: ReactNode }> {
	const ShowNotTellExperimentWrapper: React.FC<P & { children?: ReactNode }> = (props) => {
		useShowNotTellFGAAExperiment({
			isExperimentEnabled: fg('show_not_tell_fg_aa'),
			touchpointId,
		});

		const { renderTreatment, cohort } = useShowNotTellFGABExperiment({
			isExperimentEnabled: fg('show_not_tell_fg_ab'),
			touchpointId,
		});

		if (renderTreatment) {
			// pass sntCohort here for experiment components
			return (
				<ShowNotTellExperimentCohortContext.Provider value={cohort}>
					<Component {...props} analytics={{ touchpointId, sntCohort: cohort }} />
				</ShowNotTellExperimentCohortContext.Provider>
			);
		}

		const children = Children.map(props.children, (child) => {
			if (!React.isValidElement(child)) {
				return null;
			}
			return React.cloneElement(child, {
				...child.props,
				// pass cohort here for control components
				showNotTellExperimentCohort: cohort,
			});
		})?.filter((e) => e !== null);
		// if the children aren't valid react elements pass them along without any updated props
		return (
			<ShowNotTellExperimentCohortContext.Provider value={cohort}>
				{children?.length ? children : props.children}
			</ShowNotTellExperimentCohortContext.Provider>
		);
	};

	return ShowNotTellExperimentWrapper;
}
